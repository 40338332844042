window.toggleRequired = function(wrapper, value) {
    let firstPanel = wrapper.find('.panel').first();
    let inputs = firstPanel.find('[data-required]');

    for (let i = 0; i < inputs.length; i++) {
        inputs[i].required = (value === 'Yes' || value === 'Oui');
    }
}

window.toggleDisabled = function(target, value) {
    if (value === 'Yes' || value === 'Oui') {
        target.removeClass('d-none');
        target.find('.form-control, .custom-control-input').attr('disabled', false);
    }
}
