(() => {
    if (!document.getElementById('applicationForm')) {
        return;
    }
    let checkbox = $('.still-working-there');

    toggleDisableProp(checkbox);

    $(document).on('change', '.still-working-there', function () {
        toggleDisableProp($(this));
    });

    function toggleDisableProp(el) {
        let parent = el.closest('[data-end]');

        if (el.is(':checked'))
            parent.find('select').prop('disabled', true).val('');
        else
            parent.find('select').prop('disabled', false);
    }
})();
