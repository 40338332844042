(() => {
    if (!document.getElementById('applicationForm')) {
        return;
    }

    let pse = $('input[name="post_secondary_education"]:checked');
    if (pse.length) {
        toggleRequired($('#certificates'), pse.val());
        toggleDisabled($('[data-hidden-element="certificate_panel"]'), pse.val());
    }

    let workExperience = $('[name="past_work_experience"]:checked');
    if (workExperience.length) {
        toggleRequired($('#employment'), workExperience.val());
        toggleDisabled($('[data-hidden-element="employment_panel"]'), workExperience.val());
    }

    $(document).on('change', '[name="post_secondary_education"]', function () {
        toggleRequired($('#certificates'), $(this).val());
    });

    $(document).on('change', '[name="past_work_experience"]', function () {
        toggleRequired($('#employment'), $(this).val());
    });
})();
