(() => {
    if (!document.getElementById('applicationForm')) {
        return;
    }

    const addPanelBtns = document.querySelectorAll('.addPanel');

    for (let i = 0; i < addPanelBtns.length; i++) {
        let el = addPanelBtns[i];

        el.addEventListener('click', (e) => {
            let panelHolder = document.getElementById(e.target.dataset.holder);
            let clone = panelHolder.lastElementChild.cloneNode(true);

            let closePanelBtn = clone.querySelector('.close-panel');
            closePanelBtn.classList.remove('d-none');
            closePanelBtn.addEventListener('click', () => {
                panelHolder.removeChild(clone);
                fixAllPanelsAttributes(panelHolder);
            });

            fixCloneAttributes(clone, panelHolder.childElementCount);
            panelHolder.appendChild(clone);
        });
    }

    function fixAllPanelsAttributes(panelHolder) {
        let panels = panelHolder.querySelectorAll('.panel');

        for (let index = 0; index < panels.length; index++) {
            let panel = panels[index];

            if (index === 0) {
                continue;
            }

            panel.querySelector('.panel-number').innerHTML = (index + 1).toString();

            const formElements = panel.querySelectorAll('.form-control, .custom-control-input');

            for (let i = 0; i < formElements.length; i++) {
                let el = formElements[i];

                el.name = el.name.replace(/(\w+\[)\d*(\].+)/, `$1${index}$2`);

                if (!el.classList.contains('custom-control-input')) continue;

                replaceId(el, index);
            }
        }
    }

    function fixCloneAttributes(panel, index) {
        panel.querySelector('.panel-number').innerHTML = (index + 1).toString();
        const formElements = panel.querySelectorAll('input[type="text"], textarea, .custom-control-input, .custom-select');

        for (let i = 0; i < formElements.length; i++) {
            let el = formElements[i];
            el.name = el.name.replace(/(\w+\[)\d*(\].+)/, `$1${index}$2`);
            el.required = false;
            el.disabled = false;

            if (el.classList.contains('custom-control-input')) {
                replaceId(el, index);
                el.checked = false;
            }
            else {
                el.value = '';
            }
        }
    }

    function replaceId(el, index) {
        let newId = el.id.replace(/(.+_)\d+$/, `$1${index}`);
        el.id = newId;
        el.nextElementSibling.htmlFor = newId;
    }
})();
