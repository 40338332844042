(() => {
    if (!document.getElementById('applicationForm')) {
        return;
    }

    $('.custom-file-input').on('change', function () {
        let fileName = $(this).val().replace('C:\\fakepath\\', '');
        $(this).next('.custom-file-label').html(fileName);
    });
})();
