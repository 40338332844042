(() => {
    if (!document.getElementById('applicationForm')) {
        return;
    }
    $(document).on('change', '[data-toggle-hidden]', function () {
        let target, allTargetsByClass;

        if (this.tagName === 'SELECT') {
            let targetLabel = $(this).find(':selected').attr('data-toggle-hidden');
            let targetClass = $(this).find(':selected').attr('data-target-class');
            target = $(`[data-hidden-element='${targetLabel}']`);
            allTargetsByClass = $(`[data-class='${targetClass}']`);
        } else {
            target = $(`[data-hidden-element='${this.dataset.toggleHidden}']`);
            allTargetsByClass = $(`[data-class='${this.dataset.targetClass}']`);
        }

        allTargetsByClass.find('.form-control, .custom-control-input').attr('disabled', true);
        allTargetsByClass.addClass('d-none');

        if (target.length) {
            target.find('.form-control, .custom-control-input').attr('disabled', false);
            target.removeClass('d-none');
        }
    });
})();
