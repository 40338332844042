(() => {
    if (!document.getElementById('applicationForm')) {
        return;
    }
    const form = document.getElementById('applicationForm');

    $(document).on('change', '[data-hidden-target]', function () {
        showHiddenPanel($(this));
    });

    let hiddenPanelTriggers = $('[data-hidden-target]:checked');

    for (let i = 0; i < hiddenPanelTriggers.length; i++) {
        let el = hiddenPanelTriggers[i];
        showHiddenPanel($(el));
    }

    function showHiddenPanel(el) {
        let target = el.data('hidden-target');
        let show = el.data('show');
        let targetEl = $('#' + target);

        if (show) {
            targetEl.collapse('show');

            targetEl.find('[data-required]').each((i, el) => {
                el.setAttribute('required', 1)
                el.removeAttribute('data-required')
            });
        }
        else {
            targetEl.collapse('hide');

            targetEl.find('[required]').each((i, el) => {
                el.setAttribute('data-required', 1)
                el.removeAttribute('required')
            });
        }
    }

    $(document).on('click', '[data-next]', function () {
        const $this = $(this)[0];
        const next = $this.dataset.next;
        const nextPanel = $('#collapse-' + next);
        const nextPanelTrigger = $('#section-' + next).find('[data-target]');
        const parentPanel = document.getElementById('collapse-' + (next-1));
        const required = Array.prototype.slice.apply(
            parentPanel.querySelectorAll('[required]')
        );

        if (required.length) {
            if (required.some(e => !e.checkValidity())) {
                $('html, body').animate({
                    scrollTop: $(parentPanel).offset().top
                }, 200);
                form.classList.add('was-validated');
            } else {
                form.classList.remove('was-validated');
                activateNextPanel(nextPanelTrigger, nextPanel, next);
            }
        } else {
            activateNextPanel(nextPanelTrigger, nextPanel, next);
        }
    });

    function activateNextPanel(nextPanelTrigger, nextPanel, next) {
        $('#section-' + next).addClass('collapsible');
        nextPanelTrigger
            .removeClass('collapsed')
            .attr('data-target', '#collapse-' + next)
            .attr('aria-expanded', true);
        nextPanel.collapse('show');
    }

    if ($('#submitApplicationBtn').hasClass('d-none')) {
        $('[id^="collapse-"]').last().on('shown.bs.collapse', function () {
            $('#submitApplicationBtn').removeClass('d-none');
        })
    }
})();
