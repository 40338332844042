require ('./sisyphus.js');

(() => {
    if (!document.getElementById('applicationForm')) {
        return;
    }

    const form = $('#applicationForm');

    if (form.data('skipDraft')) {
        return;
    }

    const saveDraftBtn = document.querySelectorAll('[data-save]');

    const f = form.sisyphus({
        excludeFields: $('[name=_token], input[type=file]'),
        timeout: 60,
        locationBased: true,
        autoRelease: true,
        onSave: function () {
            $('#notification').remove();
            $('body').append('<div id="notification">Form draft saved</div>');
            setTimeout(() => {
                $('#notification').remove();
            }, 2000);
        },
        onRestore: function () {
            let country = $('[name="investment_program_of_interest"]');
            if (country.length && country.find(":selected").val() === 'Canada') {
                const target = $('[data-hidden-element="business_in_canada"]');
                target.removeClass('d-none');
                target.find('.form-control, .custom-control-input').attr('disabled', false);
            }

            let pse = $('input[name="post_secondary_education"]:checked');
            if (pse.length ) {
                toggleRequired($('#certificates'), pse.val());
                toggleDisabled($('[data-hidden-element="certificate_panel"]'), pse.val());
            }

            let workExperience = $('[name="past_work_experience"]:checked');
            if (workExperience.length) {
                toggleRequired($('#employment'), workExperience.val());
                toggleDisabled($('[data-hidden-element="employment_panel"]'), workExperience.val());
            }
        }
    });

    $(saveDraftBtn).on('click', function () {
        f.saveAllData();
    });

})();
