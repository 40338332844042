(() => {
    if (!document.querySelector('[data-currency-selector]')) {
        return;
    }

    const selectors = $('[data-currency-selector]')
    const rates = window.CURRENCY_EXCHANGE_RATES

    const CAD = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'CAD',
        maximumFractionDigits: 0,
        currencyDisplay: 'narrowSymbol'
    });

    const changeCurrencyValues = function(selector) {
        const target = $($(selector).data('currencySelector'))
        const currency = $(selector).val()
        const options = target.children('option')
        const rate = rates.rates[currency] || 1
        options.each(function (i, option) {
            const values = $(option).data('currencyValue')
            let label = $(option).data('originalLabel')
            $(values).each(function(i, value) {
                const originalValue = CAD.format(value).replace('$', '')
                const currencyValue = CAD.format(value * rate).replace('$', '')
                label = label.replace(originalValue, currencyValue)
            })
            $(option).text(label)
        })
    }

    selectors.each(function(index, selector) {
        changeCurrencyValues(selector)
    })

    $(document).on('change', '[data-currency-selector]', function(el) {
        changeCurrencyValues(el.target)
    })
})();
